/*====================
    About Area 
======================*/

.about-area {
    .rn-progress-bar {
        .single-progress {
            margin-top: 26px;
        }
    }
}

.rn-finding-us-area {
    .inner {
        position: relative;

        @media #{$lg-layout} {
            padding-left: 0;
            padding-top: 0;
            padding-bottom: 0;
            display: flex;
            flex-direction: column-reverse;
        }

        @media #{$md-layout} {
            padding-left: 0;
            padding-top: 0;
            padding-bottom: 0;
            display: flex;
            flex-direction: column-reverse;
        }

        @media #{$sm-layout} {
            padding-left: 0;
            padding-top: 0;
            padding-bottom: 0;
            display: flex;
            flex-direction: column-reverse;
        }
        .content-wrapper {
            .content {
                background: #101010;
                padding: 120px 100px;
                position: relative;
                z-index: 2;
                width: 800px;
                border-radius: 10px;

                @media #{$lg-layout} {
                    padding: 60px 50px;
                    width: 100%;
                    border-radius: 0;
                }

                @media #{$md-layout} {
                    padding: 60px 50px;
                    width: 100%;
                    border-radius: 0;
                }

                @media #{$sm-layout} {
                    padding: 30px 30px;
                    width: 100%;
                    border-radius: 0;
                }

                h4 {
                    color: #ffffff;
                    font-size: 36px;
                    font-weight: 500;
                    margin-bottom: 20px;
                    @media #{$sm-layout} {
                        font-size: 26px;
                        margin-bottom: 12px;
                    }
                }
                p {
                    font-size: 18px;
                    color: #968b8b;
                    font-weight: 300;
                    margin-bottom: 41px;
                    padding-right: 10%;
                    @media #{$sm-layout} {
                        margin-bottom: 24px;
                        padding-right: 0;
                    }
                }
            }
        }
        .thumbnail {
            position: absolute;
            top: 0;
            right: 0;
            height: 100%;

            @media #{$lg-layout} {
                position: static;
                height: 100%;
            }

            @media #{$md-layout} {
                position: static;
                height: 100%;
            }

            @media #{$sm-layout} {
                position: static;
                height: 100%;
            }
            img {
                border-radius: 10px;
                @media #{$lg-layout} {
                    border-radius: 0;
                }
                @media #{$md-layout} {
                    border-radius: 0;
                }
                @media #{$sm-layout} {
                    border-radius: 0;
                }
            }
        }
    }
}

.about-inner {
    @media #{$md-layout} {
        padding-top: 40px;
    }
    @media #{$sm-layout} {
        padding-top: 40px;
    }

    .icon {
        display: inline-block;
        width: 60px;
        height: 60px;
        background: var(--color-primary);
        border-radius: 100%;
        text-align: center;
        color: #fff;
        line-height: 58px;
        font-size: 27px;
        margin-bottom: 17px;
        padding-right: 3px;

        svg {
            stroke-width: 1;
        }
    }
    .section-title {
        h2 {
            &.title {
                line-height: 1.3;
                margin-bottom: 35px;
            }
        }
        p {
            &.description {
                font-weight: 400;
                color: rgba(29, 29, 36, 1);
                a {
                    color: rgba(29, 29, 36, 1);
                    &:hover {
                        color: var(--color-primary);
                    }
                }
            }
        }
    }
}

.about-wrapper {
    .thumbnail {
        position: relative;
        z-index: 2;
        img {
            border-radius: 8px;
            box-shadow: none;
        }
    }
}

.about-position-top {
    @media #{$lg-layout} {
        padding-top: 120px;
    }
    @media #{$md-layout} {
        padding-top: 80px;
    }
    @media #{$sm-layout} {
        padding-top: 60px;
    }
    .thumbnail {
        margin-top: -130px;
        @media #{$lg-layout} {
            margin-top: 0;
        }
        @media #{$laptop-device} {
            margin-top: -89px;
        }
        @media #{$md-layout} {
            margin-top: 0;
        }
        @media #{$sm-layout} {
            margin-top: 0;
        }
    }
}

.about-us-list {
    margin-top: 50px;
    @media #{$md-layout} {
        margin-top: 30px;
    }
    @media #{$sm-layout} {
        margin-top: 30px;
    }
    h3 {
        &.title {
            font-size: 30px;
            @extend %fontWeight500;
            margin-bottom: 20px;
            @media #{$sm-layout} {
                font-size: 26px;
                margin-bottom: 8px;
            }
        }
    }
    p {
        font-weight: 300;
        padding-right: 10%;
    }
}

.rn-content-box-style--1 {
    .content {
        text-align: left;
        padding: 0 120px;
        @media #{$md-layout} {
            padding: 0 60px;
        }
        @media #{$sm-layout} {
            padding: 0 40px;
        }
        p {
            &.subtitle {
                color: $heading-color;
                font-size: 16px;
                font-weight: 400;
                padding: 8px 25px;
                display: inline-block;
                border-radius: 4px;
                span {
                    color: var(--color-primary);
                }
            }
        }
        h2 {
            margin-top: 11px;
            margin-bottom: 20px;
            font-size: 36px;
            line-height: 50px;
            font-weight: 500;
            margin-bottom: 20px !important;
            @media #{$lg-layout} {
                margin-top: 7px;
            }
            @media #{$md-layout} {
                margin-top: 7px;
            }
            @media #{$sm-layout} {
                margin-top: 7px;
            }
        }
        p {
            color: rgba(29, 29, 36, 0.75);
            font-size: 18px;
            line-height: 30px;
        }
    }
}

// About Style Two
.rm-about-style-2 {
    .about-inner {
        .section-title {
            h2 {
                &.title {
                    line-height: 1.3;
                    margin-bottom: 26px;
                }
            }
            p {
                &.description {
                    font-weight: 300;
                    color: rgba(29, 29, 36, 1);
                    a {
                        color: rgba(29, 29, 36, 1);
                        &:hover {
                            color: var(--color-primary);
                        }
                    }
                }
            }
        }
    }
}
