/*--------------------------
    Color Darker Version  
----------------------------*/

.active-dark {
    background: #101010;
    [data-overlay='6']:before,
    [data-black-overlay='6']:before,
    [data-white-overlay='6']:before {
        display: none;
    }
    [data-overlay]:before,
    [data-black-overlay]:before,
    [data-white-overlay]:before {
        display: none;
    }
    .service-white .service__style--1 .content h4.title {
        color: #ffffff;
    }

    &.bg_color--9 {
        .creative-service-wrapper .row.creative-service .service.service__style--2,
        .header-area.header--fixed.sticky,
        .backto-top > div,
        .bg_color--6 {
            background: #091020;
        }
    }

    .service-main-wrapper .service.service__style--2,
    .im_counterup {
        background: #0c0c0c;
    }

    .bg_image--3,
    .bg_image--1,
    .rn-testimonial-content .inner p::before,
    .rn-testimonial-content .inner p::after,
    .footer-right {
        background-image: inherit !important;
    }
    .bg_color--1,
    .brand-separation {
        background: #191919;
    }

    .bg_color--5,
    .bg_color--6 {
        background: #101010;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    .h1,
    .h2,
    .h3,
    .h4,
    .h5,
    .h6 {
        color: #ffffff;
    }
    a.btn-transparent {
        color: #ffffff;
    }
    .section-title p,
    .about-us-list p,
    .about-inner .section-title p.description,
    .service.service__style--2 .content p,
    .counterup_style--1 p.description,
    .rn-testimonial-content .author-info h6,
    .slide.slider-style-3 .inner > span,
    .single-tab-content ul li a,
    .single-tab-content ul li {
        color: rgba(198, 201, 216, 0.75);
    }

    ul.tab-style--1 {
        border-color: #2d3548;
    }
    ul.tab-style--1 li::before {
        background: rgba(198, 201, 216, 0.75);
    }
    ul.tab-style--1 li.react-tabs__tab--selected {
        color: var(--color-white);
    }

    .service.service__style--2:hover .content h3.title,
    .rn-testimonial-content .inner p,
    .section-title p a,
    .slide.slider-style-3 .inner h1.title,
    .color-black .mainmenunav ul.mainmenu > li > a,
    ul.social-share.social-style--2.color-black li a,
    .header-area.color-black a.rn-btn,
    ul.tab-style--1 li {
        color: #ffffff;
    }
    .portfolio-sacousel-inner .slick-dots li button::after,
    .rn-slick-dot .slick-dots li button::after {
        background: #ffffff;
        box-shadow: inset 0 0 0 5px #ffffff;
    }

    .creative-service-wrapper .row.creative-service .service.service__style--2 {
        background: #191919;
    }

    .contact-form--1 input,
    .contact-form--1 textarea,
    .header-area.color-black a.rn-btn {
        border: 2px solid rgba(255, 255, 255, 0.2);
    }
    .header-area.color-black a.rn-btn:hover {
        border-color: var(--color-primary);
    }
    .header-area.header--fixed.sticky {
        background: #191919;
    }
    .header-area.header--fixed.sticky .header-wrapper a.rn-btn {
        color: #ffffff;
        border-color: #ffffff;
    }
    .header-area.header--fixed.sticky .header-wrapper a.rn-btn:hover {
        color: #ffffff;
        border-color: var(--color-primary);
    }
    .bg_image--25 {
        background-color: #101010;
        // background-image: url(/assets/images/bg/bg-image-28.jpg) !important;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
    }
    .backto-top > div {
        background-color: #0c0c0c;
    }
    .backto-top svg {
        color: #fff;
    }
    .contact-form--1 input,
    .contact-form--1 textarea {
        color: rgba(198, 201, 216, 0.75);
    }

    .personal-portfolio-slider::before {
        display: none;
    }

    ul.testimonial-thumb-wrapper li .thumb::after {
        background-color: rgba(0, 0, 0, 0.5);
    }

    ul.testimonial-thumb-wrapper li.react-tabs__tab--selected .thumb::after {
        background-color: rgba(0, 0, 0, 0);
    }

    .header-area.color-black a.btn-default.btn-border.btn-opacity {
        color: rgba(198, 201, 216, 0.75);
        border-color: rgba(198, 201, 216, 0.75);
    }

    .header-area.color-black a.btn-default.btn-border.btn-opacity:hover {
        color: var(--color-white);
        border-color: var(--color-primary);
    }
    .im_address .link {
        color: rgba(198, 201, 216, 0.75);
    }
    .im_address .link:hover {
        color: var(--color-primary);
    }
    .rn-progress-bar .single-progress .title {
        color: rgba(198, 201, 216, 0.75);
    }

    .rn-progress-bar .single-progress .label {
        color: rgba(198, 201, 216, 0.75);
    }

    .im_portfolio:hover {
        box-shadow: 0 25px 55px rgba(20, 23, 29, 0.05);
    }

    @media #{$md-layout} {
        .header-style-two .humberger-menu span.text-white {
            color: #c6c9d8 !important;
        }
        .header-area .header-wrapper .mainmenunav ul.mainmenu {
            background: #101010;
        }
        .header-area .header-wrapper.menu-open .close-menu span {
            color: #c6c9d8 !important;
        }
        .header-area.header--fixed.sticky .humberger-menu span.text-white {
            color: #c6c9d8 !important;
        }
        .header-area .header-wrapper .mainmenunav ul.mainmenu li a {
            color: #ffffff;
        }
        .header-area .header-wrapper .mainmenunav ul.mainmenu li.has-droupdown > a::after {
            border-color: #ffffff transparent transparent transparent;
        }
        .header-area .header-wrapper .mainmenunav ul.mainmenu li.has-droupdown > a.open::after {
            border-color: transparent transparent var(--color-primary) transparent;
        }
    }
    @media #{$sm-layout} {
        .header-style-two .humberger-menu span.text-white {
            color: #c6c9d8 !important;
        }
        .header-area .header-wrapper .mainmenunav ul.mainmenu {
            background: #101010;
        }
        .header-area .header-wrapper.menu-open .close-menu span {
            color: #c6c9d8 !important;
        }
        .header-area.header--fixed.sticky .humberger-menu span.text-white {
            color: #c6c9d8 !important;
        }
        .service.service__style--2 .content p {
            color: #ffffff;
        }
        .header-area .header-wrapper .mainmenunav ul.mainmenu li a {
            color: #ffffff;
        }
        .header-area .header-wrapper .mainmenunav ul.mainmenu li.has-droupdown > a::after {
            border-color: #ffffff transparent transparent transparent;
        }
        .header-area .header-wrapper .mainmenunav ul.mainmenu li.has-droupdown > a.open::after {
            border-color: transparent transparent var(--color-primary) transparent;
        }
    }

    .pv-tab-button {
        border-color: #403c3c;
    }
    .accodion-style--1 .accordion__item .accordion__heading .accordion__button {
        color: #fff;
    }
    .accodion-style--1 .accordion__item .accordion__heading .accordion__button::after {
        background: #353434;
    }

    .accodion-style--1 .accordion__item .accordion__panel p {
        color: rgba(198, 201, 216, 0.75);
    }
}

.active-dark a.btn-default.btn-border.btn-opacity,
.active-dark button.btn-default.btn-border.btn-opacity {
    border: 2px solid var(--color-opacity-1) !important;
    color: var(--color-white) !important;
}

.active-dark .header-area.header--fixed.default-color.sticky .mainmenunav ul.mainmenu > li > a {
    color: #ffff;
}
